@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  margin-top: 24px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: 100%;
  margin-right: 8px;
}

.lastNameRoot {
  width: 50%;
  margin-bottom: 16px;
  margin-right: 8px;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 16px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 40px;
}

.button {
  border-radius: 8px;
  margin-right: 24px;
  height: 39px;
  background: #0b5132;
  border-radius: 8px;
  color: #fbfbfc;
  border: 1px solid #0b5132;
  &:hover {
    cursor: pointer;
  }
}

.buttonSelected {
  border-radius: 8px;
  border: 1px #c6c7c8 solid;
  color: #75767a;
  background-color: transparent;
  margin-right: 24px;
  height: 39px;

  &:hover {
    cursor: pointer;
  }
}

.buttonSelected:hover {
  color: #75767a;

  &:hover {
    cursor: pointer;
  }
}

.PrimaryButton {
  background-color: var(--purpleTecmilenio);
}

.termsnConditionsWrapper {
  width: 418px;
  color: #737e98;
  font-size: 12px;
  letter-spacing: 0.6px;
  font: 400;
  text-align: center;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 16px;
  display: inline-flex;
  flex-direction: row;
}

.logoSignUp {
  width: 445.784px;
  height: 69px;
}
