@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 32px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-top: 24px;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  color: #0b5132;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.PrimaryButton {
  background-color: var(--purpleTecmilenio);
}

.containerLogos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 40px;
}

.labelLogin {
  color: #141619;
  text-align: center;
  font-size: 16px;
  font-size: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-top: 24px;
}

.divider { 
  width: 100%;
  height: 0;
  border: 1px #C6C7C8 solid;
  margin-top: 30px;
}

.createAccountWrapper { 
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.labelAccount { 
  color: #141619;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16;
  word-wrap: break-word;
  margin-right: 10px;
}

.labelCreateAccount { 
  color: #0B5132;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  letter-spacing: 0.16;
  word-wrap: break-word;
}

.logoTecmilenio{ 
  width: auto;
  height: auto;
  margin-top: 10%;

  @media --viewportMedium {
    width: 194px;
    height: 69px;
  }
}

.logoEmyc{ 
  width: auto;
  height: auto;
  
  @media --viewportMedium {
    width: 337px;
    height: 64px;
    margin-top: 32px;
  }
}